import { Box } from "@chakra-ui/react";
export default function Multiplecards(){
    return (
    <Box className="cards-wrapper">
    <Box className = "animation">
             <h2> OSC Provides </h2>
            <Box className = "animation-Body">
                    T1 <br/>
                    T2 <br/>
                    T3 <br/>
            </Box>
            <h2> Services </h2>
    </Box>
        <Box className = "cards-container">
            <Box className = "card"> 
                <h2> Student Led </h2>
                OSC brings together bright, motivated students in a wide variety of different majors who share a passion for technology and community service. Our team consists of forward-thinking students who are passionate about combining their technological skills with a desire to contribute meaningfully to the Eugene community.
            </Box>

            <Box className = "card"> 
                <h2> Services </h2>
                Our projects range from developing databases and web applications to creating mobile apps and analytical tools, all tailored to meet the specific needs of our clients. Through this collaborative approach, we not only help non-profits achieve their goals but also contribute positively to our community's well-being.
            </Box>

            <Box className = "card">
                <h2> Our Approach</h2>
                We offer our student members a dynamic learning environment where they can gain hands-on experience in real-world software development projects. This experiential learning approach equips them with valuable skills in problem-solving, teamwork, and project management, preparing them for successful careers in the tech industry.
            </Box>
        </Box>
    </Box>
    );
}
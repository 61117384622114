
import {Box} from "@chakra-ui/react";

// Text on mission statement and short blurb to be loaded into home page

export default function Missionstatement(){
    return(
        <Box className="mission-statement">
            <Box className="mission-statement-title">OUR MISSION</Box>
            <Box className="mission-statement-text">Our mission is to leverage the power of software to address real-world challenges, fostering an environment of learning, innovation, and social responsibility.</Box>
        </Box>
    )
}


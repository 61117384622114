import { Link as ReactRouterlink} from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Link, HStack, Text, Image, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { PAGE_ROUTES } from "../data/routes.js";
import Logo from '../images/Logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
/* Deafault Navbar for all pages on website */

export default function Navbar(){
    return(
        <HStack className="Navbar">
            <Link className="NavLink" id="NavLinkLogo" as = {ReactRouterlink} to = {PAGE_ROUTES.ROOT}  >
                <Image src= {Logo} alt="OSC Logo" />
            </Link>
            <NavLink className="NavLink" activeClassName = "active" as = {ReactRouterlink} to  = {PAGE_ROUTES.ROOT}  >
                <Text>Home</Text>
            </NavLink>
            <Link className="NavLink" activeClassName = "active" as = {ReactRouterlink} to = {PAGE_ROUTES.Contact}  >
                <Text>Contact</Text>
            </Link>
            <Link className="NavLink" as = {ReactRouterlink} to = {PAGE_ROUTES.Aboutus}  >
                <Text>About Us</Text>
            </Link>
            <Link className = "LinkedIn"  as = {ReactRouterlink} to = {PAGE_ROUTES.Linkedin} >
                    <FontAwesomeIcon icon={faLinkedin} />
            </Link>
            <Link className = "Instagram" as = {ReactRouterlink} to = {PAGE_ROUTES.Instagram} >
                    <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Menu>
                <MenuButton className = "menu-btn"> <FontAwesomeIcon icon= {faBars} /> </ MenuButton>
                <MenuList>
                    <MenuItem className = "menu-btn">
                        <Link as = {ReactRouterlink} to ={PAGE_ROUTES.ROOT}> Home </Link>
                    </MenuItem>
                    <MenuItem className = "menu-btn">
                        <Link as = {ReactRouterlink} to ={PAGE_ROUTES.Contact}> Contact</Link>
                    </MenuItem>
                    <MenuItem className = "menu-btn">
                    <Link className="NavLink" as = {ReactRouterlink} to = {PAGE_ROUTES.Aboutus}> About Us </Link>
                    </MenuItem>
                    <MenuItem className = "menu-btn">
                        <Link as = {ReactRouterlink} to ={PAGE_ROUTES.Linkedin}> <FontAwesomeIcon icon = {faLinkedin} /> </Link>
                    </MenuItem>
                    <MenuItem className = "menu-btn">
                        <Link as = {ReactRouterlink} to ={PAGE_ROUTES.Instagram}> <FontAwesomeIcon icon = {faInstagram} /> </Link>
                    </MenuItem>
                </MenuList>
            </Menu>
            </HStack>
    );
}
import { Box} from "@chakra-ui/react";
import Navbar from "../components/Navbar.jsx"
import Calltoaction from "../components/Calltoaction.jsx";
import Moreinfo from "../components/Moreinfo.jsx";
import Introduction from "../components/Introduction.jsx";
import Layout from "../components/Layout.jsx";
import BottomSegment from "../components/Bottomsegment.jsx";
import Multiplecards from "../components/Multiplecards.jsx";

// This is the Hompage which is built with 4 components 

export default function Home (){
    return(
        <Layout>
            <Box>
                <Navbar />
                <Introduction />
                <Calltoaction /> 
                <Moreinfo />
                <Multiplecards />
                <BottomSegment />
            </Box>
        </Layout>
    );
}
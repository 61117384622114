import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './styles/index.css';
import App from './components/App';


const rootElement = document.getElementById('root');
// renders the App component into the div with ID root, wrapped by React StrictMode and HashRouter
// https://www.upgrad.com/blog/react-strict-mode/ 
// https://reactrouter.com/en/main/router-components/hash-router
ReactDOM.render(
  <StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </StrictMode>,
  rootElement
);
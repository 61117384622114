import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { PieChart, Pie, LabelList } from 'recharts';

const data = [
  {
    majorname: 'Political Science',
    major: 1,
  },
  {
    majorname: 'Japanese',
    major: 1,
  },
  {
    majorname: 'Computer Science',
    major: 4,
  },
  {
    majorname: 'Data Science',
    major: 2,
  },
  {
    majorname: 'Advertising',
    major: 1,
  },
  {
    majorname: 'Economics',
    major: 1,
  },
  {
    majorname: 'Accounting',
    major: 1,
  },
  {
    minorname: 'Computer Inoformation and Technology',
    minor: 4,
  },
  {
    minorname: 'Philosophy',
    minor: 1,
  },
  {
    minorname: 'Law',
    minor: 1,
  },
  {
    minorname: 'Buisness Administration',
    minor: 1,
  },
  {
    yearname: 'Freshman',
    year: 1,
  },
  {
    yearname: 'Sophomore',
    year: 3,
  },
  {
    yearname: 'Junior',
    year: 3,
  },
  {
    yearname: 'Senior',
    year: 2,
  },
];

export default function StudentGraph() {
  const [value, setValue] = useState('major');
  const [name, setName] = useState('majorname');

  return (
    <Box className='piechart'>
      <Box className='upper'>
      <Box className='title'>Who Makes Up OSC?</Box>
      <Box className='piechart-buttons'>
        <Button onClick={() => {setValue('major'); setName('majorname')}}>Majors</Button>
        <Button onClick={() => {setValue('minor'); setName('minorname')}}>Minors</Button>
        <Button onClick={() => {setValue('year'); setName('yearname')}}>Year</Button>
      </Box>
      </Box>
      <PieChart width={1100} height={600} className='chart'>
        <Pie
          dataKey={value}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={260}
          fill='#86b58f'
          labelLine={false}
          label
          startAngle={180}
          endAngle
        >
          <LabelList dataKey={name} position="outside" stroke = 'none' fill='black'/>
        </Pie>
      </PieChart>
    </Box>
  );
}

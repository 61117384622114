import { Box } from "@chakra-ui/react";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Studentform from "./Studentform";
import Clientform from "./Clientform";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../data/routes.js";


//client inquiry input boxes with validation to be loaded into contact page

export default function Inputbox() {
   const studentForm = useRef();
   const clientForm = useRef();
   const [minorselection, setMinorSelection] = useState([]);
   const [majorselection, setMajorSelection] = useState([]);
   const [studentname, setStudentname] = useState("");
   const [uoemail, setUoemail] = useState("");
   const [why, setWhy] = useState("");
   const [time, setTime] = useState("");
   const [study, setStudy] = useState("");
   const [org, setOrg] = useState("");
   const [email, setEmail] = useState("");
   const [clientname, setClientname] = useState("");
   const [description, setDescription] = useState("");
   const [goal, setGoal] = useState("");
   const [datetime, setDatetime] = useState("");

   const clientnameField = document.getElementById("clientname");
   const emailField = document.getElementById("email"); 
   const studentnameField = document.getElementById("studentname");
   const uoemailField = document.getElementById("uoemail");
 
   const navigate = useNavigate()

   // function: validates StudentName to have lower or upper case then a space then a last name that may also contain a hyphen or apostrophe
   // TODO: fix just first name is accepted
  
   function validateStudentName(input) {
     var regName = /^[a-zA-Z]+(?:[' -][a-zA-Z]+)*$/
     if (!regName.test(input)) {
       studentnameField.style.borderColor = 'red'
       alert("Please enter your full name (first & last name).");
       return false;
     } else {
       return true;
     }
   }

 // function: validates Client Name to have lower or upper case then a space then a last name that may also contain a hyphen or apostrophe

   function validateClientName(input) {
     var regName = /^[a-zA-Z]+(?:[' -][a-zA-Z]+)*$/
     if (!regName.test(input)) {
       clientnameField.style.borderColor = 'red'
       alert("Please enter your full name (first & last name).");
       return false;
     } else {
       return true;
     }
   }

   // function: validates a string using typeof


   function validateString(input){
     if(typeof input !== 'string'){
       alert("Please enter a organization.");
       return false;
     } else {
       return true;
     }
   }

   // function: validates a number using isNaN

   function validateNumber(input) {
     if (isNaN(input)) {
       alert("Please enter a valid number.");
       return false;
     } else {
       return true;
    }
   }

   // function: validates an email by searching for @ and .

   function validateEmail(input) {
     if (input.indexOf('@') === -1 || input.indexOf('.') === -1) {
       emailField.style.borderColor = 'red'
       alert("Please enter a valid email.");
       return false;
     } else {
       return true;
     }
   }

  // function: validates an email by searching for uoregon.edu

   function validateUOemail(input) {
     if (input.indexOf("@uoregon.edu") === -1) {
       uoemailField.style.borderColor = 'red'
       alert("Please enter a valid UO email.(ends in uoregon.edu)");
       return false;
     } else {
       return true;
     }
   }

   // function: validate the radio buttons by name

   function validateRadioButtons(radioGroupName) {
     const radioButtons = document.getElementsByName(radioGroupName);
     const checkedButton = Array.from(radioButtons).find(
       (button) => button.checked
     );
     return !!checkedButton;
   }

   // function: that uses switch case to check for a specific validation

   function validate(input, type) {
     switch (type) {
       case "studentname":
         return validateStudentName(input);
         case "clientname":
           return validateClientName(input);
       case "string":
         return validateString(input);
       case "number":
         return validateNumber(input);
       case "email":
         return validateEmail(input);
       case "uoemail":
         return validateUOemail(input);
       default:
         alert("Unsupported type.");
         return false;
     }
   }

   // function: to check if all values are accounted for based on formRef

   function validateAll(formRef) {
     const graduationYearValidation = validateRadioButtons("GradRadio");
     const studenthearAboutValidation = validateRadioButtons("ContactRadio");
     const clienthearAboutValidation = validateRadioButtons("ContactRadio");

     if (formRef === clientForm) {
       if (
         !org ||
         !clientname ||
         !email ||
         !description ||
         !goal ||
         !datetime ||
         !clienthearAboutValidation
       ) {
         return false;
       } else {
         return true;
       }
     } 
     else if (formRef === studentForm) {
       if (
         !studentname ||
         !uoemail ||
        majorselection.length === 0 ||
        minorselection.length === 0 ||
        !why ||
         !time ||
         !study ||
        !studenthearAboutValidation ||
         !graduationYearValidation
       ) {
         return false;
       } else {
         return true;
       }
     }
   }

   // function: arrow function that validates name and email values based on formRef

   const validateFormFields = (formRef) => {
     if (formRef === clientForm) {
       return validate(clientname, "clientname") && validate(email, "email");
    } else if (formRef === studentForm) {
       return validate(studentname, "studentname") && validate(uoemail, "uoemail");
     }
   };

   // function: send email with emailjs

   const sendEmail = (formRef) => {
     return emailjs.sendForm(
       process.env.REACT_APP_EMAIL_SERVICE_ID,
       process.env.REACT_APP_EMAIL_TEMPLATE_ID,
       formRef.current,
       {
         publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
       }
     );
   };

 
   // function: submit confirmation then sending email and returning success ir failed

   const handleFormSubmission = (formRef) => {
    let doSubmit = window.confirm("Submit?");
     if (doSubmit) {
       return sendEmail(formRef).then(
         () => {
           console.log("SUCCESS!");
         },
         (error) => {
           console.log("FAILED...", error);
         }
       );
    }
   };

  // function: submit form that validates the form then submits it

   const submitForm = (e, formRef) => {
     e.preventDefault();
    if (validateAll(formRef) === true) {
               if (validateFormFields(formRef)) {
         handleFormSubmission(formRef);
         navigate(PAGE_ROUTES.Thankyou)
       }
     } else {
       alert("All fields are required.");
     }
   };

   return (
     <Box>
       <Box className="heading">
         {" "}
         Thank you for your interest in Oregon Software Consulting! <br /> <br />{" "}
         We hope to see you soon
       </Box>
       <Tabs>
         <TabList>
           <Tab>Student Inquiry</Tab>
           <Tab>Client Inquiry</Tab>
         </TabList>
         <TabPanel>
           <Box className="title"> Student Inquiry </Box>
           <Studentform
             studentForm={studentForm}
             submitForm={submitForm}
             setMajorSelection={setMajorSelection}
             setMinorSelection={setMinorSelection}
             setUoemail={setUoemail}
             setWhy={setWhy}
             setTime={setTime}
             setStudy={setStudy}
             setStudentname={setStudentname}
           />
         </TabPanel>
         <TabPanel>
           <Box className="title"> Client Inquiry </Box>
           <Clientform
             clientForm={clientForm}
             submitForm={submitForm}
             setOrg={setOrg}
             setEmail={setEmail}
             setClientname={setClientname}
             setDescription={setDescription}
             setGoal={setGoal}
             setDatetime={setDatetime}
           />
         </TabPanel>
       </Tabs>
     </Box>
  );
 }
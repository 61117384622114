import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import { Link } from "@chakra-ui/react";
import { PAGE_ROUTES } from "../data/routes.js";
import {Link as ReactRouterlink} from "react-router-dom";

export default function BottomSegment () {
    return (
    <div className="bottom-segment">
      <Link className = "icon-link" as = {ReactRouterlink} to = {PAGE_ROUTES.Linkedin} >
            <FontAwesomeIcon className = "icon" icon={faLinkedin} />
            </Link>
        <Link className = "icon-link" as = {ReactRouterlink} to = {PAGE_ROUTES.Instagram} >
            <FontAwesomeIcon className = "icon" icon={faInstagram} />
        </Link>
    </div>
    )
}
import { Box,Input,FormLabel,Button,Stack } from "@chakra-ui/react"
import Contactradio from "./Contactradio";

export default function Clientform({clientForm, submitForm, setOrg ,
  setEmail,
  setClientname ,
  setDescription,
  setGoal,
  setDatetime ,}){
    return(
        <Box className="contact">
            <Box className="contact-inquiry">
              <form ref={clientForm}>
                <Stack spacing={10} className="inputbox">
                  <FormLabel htmlFor="organization">Organization*</FormLabel>
                  <Input
                    type="text"
                    placeholder="JohnDoenation"
                    fontSize="lg"
                    id="organization"
                    className="input"
                    name="message"
                    onChange={(e) => setOrg(e.target.value)}
                  />
                  <FormLabel htmlFor="name">
                    Contact Name (first last)*
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="John Doe"
                    id="clientname"
                    className="input"
                    name="message"
                    onChange={(e) => setClientname(e.target.value)}
                  />
                  <FormLabel htmlFor="email">Email *</FormLabel>
                  <Input
                    type="email"
                    id="email"
                    placeholder="johndoe@gmail.com"
                    className="input"
                    name="message"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <FormLabel htmlFor="Goal">What is your goal? *</FormLabel>
                  <textarea
                    type="textarea"
                    placeholder="Goal"
                    id="Goal"
                    name="message"
                    onChange={(e) => setGoal(e.target.value)}
                  />
                  <FormLabel htmlFor="project-description">
                    Project Description *
                  </FormLabel>
                  <textarea
                    type="textarea"
                    placeholder="Project Description"
                    id="project-description"
                    name="message"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <FormLabel htmlFor="date-time">
                    What days/times are your most available?*
                  </FormLabel>
                  <textarea
                    type="textarea"
                    placeholder="dates and times"
                    id="date-time"
                    name="message"
                    onChange={(e) => setDatetime(e.target.value)}
                  />
                  <FormLabel htmlFor="radio">
                    Where did you hear about us? *
                  </FormLabel>
                  <Contactradio />
                  <FormLabel htmlFor="questions">Questions?</FormLabel>
                  <textarea
                    type="textarea"
                    placeholder="Questions?"
                    id="questions"
                    name="message"
                  />
                </Stack>
                <Button onClick={(e) => submitForm(e, clientForm)}>
                  Submit
                </Button>
              </form>
            </Box>
          </Box>
    )
}
import { Box } from "@chakra-ui/react"

export default function Contractradio(){
    return(
    <Box className="ContactRadio">
                    <input
                      type="radio"
                      id="Website"
                      name="ContactRadio"
                      value="Website"
                    />
                      <label for="Website">Website</label>
                    <br />
                    <input
                      type="radio"
                      id="Flyer"
                      name="ContactRadio"
                      value="Flyer"
                    />
                      <label for="Flyer">Flyer</label>
                    <br />
                    <input
                      type="radio"
                      id="Word of Mouth"
                      name="ContactRadio"
                      value="Word of Mouth"
                    />
                      <label for="Word of Mouth">Word of Mouth</label>
                    <br />
                    <input
                      type="radio"
                      id="Handshake"
                      name="ContactRadio"
                      value="Handshake"
                    />
                      <label for="Handshake">Handshake</label>
                    <br />
                    <input
                      type="radio"
                      id="Other"
                      name="ContactRadio"
                      value="Other"
                    />
                      <label for="Other">Other</label>
                    <br />
                  </Box>)
}
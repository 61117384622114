import { Box} from "@chakra-ui/react";
import {serviceSlidesData } from '../data/AboutData';
import { useState } from "react";

import '../styles/App.css';

const CarouselItem = ({ item, width }) => {
  return (
    <div className="carousel-item" style={{ width: width }}>
      <img className="carousel-img" src={item.icon} alt={item.alt} />
      
      <div className="carousel-item-text">
      <div className="carousel-item-title">{item.title}</div>
      <div className="carousel-item-desc">{item.description}</div>
      </div>
    </div>
  );
};
const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const items = serviceSlidesData
  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }

    setActiveIndex(newIndex);
  };
  return (
    <div className="carousel">
      <div
        className="inner"
        style={{ transform: `translate(-${activeIndex * 100}%)`
     }}
      >
        {items.map((item,index) => {
          return <CarouselItem key = {index} item={item} width={"100%"} />;
        })}
      </div>

      <div className="carousel-buttons">
        <button
          className="button-arrow"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          <span class="material-symbols-outlined">arrow_back_ios</span>{" "}
        </button>
        <div className="indicators">
          {items.map((item, index) => {
            return (
              <button
              key={index}
                className="indicator-buttons"
                onClick={() => {
                  updateIndex(index);
                }}
              >
                <span
                  className={`material-symbols-outlined ${
                    index === activeIndex
                      ? "indicator-symbol-active"
                      : "indicator-symbol"
                  }`}
                >
                  radio_button_checked
                </span>
              </button>
            );
          })}
        </div>
        <button
          className="button-arrow"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          <span class="material-symbols-outlined">arrow_forward_ios</span>
        </button>
      </div>
    </div>
  );
};


  export default function Services() {
    return (
      <Box>
        <Box className="service-title">Our Featured Project</Box>
      <Box display="block" width="100%" paddingX={["1rem", "2rem", "4rem"]} marginTop="1rem" alignItems={"center"}>
  <Carousel />
</Box>
</Box>
    );
  }
  
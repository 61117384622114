import { Box,Image} from "@chakra-ui/react";
import tykson from '../images/homepage/Tykson.jpg'
import Homepagetext from "../data/Homepagetext";
import Missionstatement from "../data/Missionstatement";

// image and text to be displayed onto home page

export default function Moreinfo(){
    return(
    <Box className="background">
    <Missionstatement />
    <Box className="Moreinfo">
        <Image src={tykson} />
        <Box className="information">
        <Homepagetext />
        </Box>
    </Box>
    </Box>
);
}